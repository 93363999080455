import React from "react"
import { Link } from "gatsby"
import Layout from "../../components/Layout/Layout"
import SEO from "../../components/Seo/Seo"
import styled from 'styled-components'
import PageHeader from '../../components/Layout/PageHeader/PageHeader'
import SectionPageMargin from '../../components/Layout/Section/SectionPageMargin'
import Heading2 from '../../components/Type/Heading2'
import Paragraph1 from '../../components/Type/Paragraph1'
import ReadyToGetStarted from '../../components/ReadyToGetStarted/ReadyToGetStarted'

const ValuationPage = ({location}) =>
(
  <Layout>

    <SEO title="The fastest (selling) prestige cars in Australia"
      description="Join us on a deep-drive of the fastest selling cars in Australia valued at over $50,000."
      location={location}
    />

    <PageHeader
      title="The fastest (selling) prestige cars in Australia"
      text="Join us on a deep-drive of the fastest selling cars in Australia valued at over $50,000."
    />

    <SectionPageMargin>

      <ContentStyle>
        <Heading2>Which cars are the fastest sellers?</Heading2>

        <Paragraph1>
          Last updated: May 2021
        </Paragraph1>

        <Paragraph1>
          There has been a lot of commentary about how hot the Australian used car market is at the moment, with prices appreciating instead of depreciating for some segments of cars.

          </Paragraph1>

        <Paragraph1>
          We were interested to see which were the <strong>fastest selling</strong> prestige cars in Australia, and the results might surprise you.
          </Paragraph1>

        <Paragraph1>
          For this report we looked at data from the past two months only, and split it into three segments;

          <ol>
            <li>Fastest selling prestige cars valued over $50,000</li>
            <li>Fastest selling prestige cars valued over $100,000</li>
            <li>Fastest selling European brand cars over $50,000</li>
          </ol>

        </Paragraph1>

        <Paragraph1>
          Here are the results;
        </Paragraph1>

        <div>
          <strong>Fastest selling used prestige cars valued over $50,000</strong><br />
          <table>
            <tbody>
              <tr>
                <td><strong>Rank</strong></td>
                <td><strong>Car make, model and year</strong></td>
                <td><strong>Average days to sell</strong></td>
                <td><strong>Average price</strong></td>
              </tr>
              <tr>
                <td>1.</td>
                <td>2020 Mitsubishi Pajero Sport Exceed QF</td>
                <td>9 days</td>
                <td>$55,412</td>
              </tr>
              <tr>
                <td>2.</td>
                <td><Link to="/price/2020-toyota-landcruiser-vx-vdj200r/" title="2020 Toyota Landcruiser VX VDJ200R price guide Australia">2020 Toyota Landcruiser VX VDJ200R</Link></td>
                <td>9 days</td>
                <td>$124,784</td>
              </tr>
              <tr>
                <td>3.</td>
                <td><Link to="/price/2015-land-rover-discovery-tdv6-series-4/" title="2015 Land Rover Discovery TDV6 Series 4 price guide Australia">2015 Land Rover Discovery TDV6 Series 4</Link></td>
                <td>10 days</td>
                <td>$59,372</td>
              </tr>
              <tr>
                <td>4.</td>
                <td><Link to="/price/2020-toyota-landcruiser-sahara-vdj200r/" title="2020 Toyota Landcruiser Sahara VDJ200R price guide Australia">2020 Toyota Landcruiser Sahara VDJ200R</Link></td>
                <td>10 days</td>
                <td>$145,536</td>
              </tr>
              <tr>
                <td>5.</td>
                <td>2020 Isuzu MU-X LS-T (No Series)</td>
                <td>11 days</td>
                <td>$52,073</td>
              </tr>
            </tbody>
          </table>
        </div>


        <Paragraph1>
          Anyone with a passing knowledge of large 4WD’s will spot the trend here! Aussie’s are looking to snap up a big car and hit the bush with the family ASAP!
        </Paragraph1>

        <div>
          <strong>Fastest selling used prestige cars valued over $100,000</strong>
          <table>
            <tbody>
              <tr>
                <td><strong>Rank</strong></td>
                <td><strong>Car make, model and year</strong></td>
                <td><strong>Average days to sell</strong></td>
                <td><strong>Average price</strong></td>
              </tr>
              <tr>
                <td>1.</td>
                <td><Link to="/price/2020-toyota-landcruiser-vx-vdj200r/" title="2020 Toyota Landcruiser VX VDJ200R price guide Australia">2020 Toyota Landcruiser VX VDJ200R</Link></td>
                <td>9 days</td>
                <td>$124,784</td>
              </tr>
              <tr>
                <td>2.</td>
                <td><Link to="/price/2020-toyota-landcruiser-sahara-vdj200r/" title="2020 Toyota Landcruiser Sahara VDJ200R price guide Australia">2020 Toyota Landcruiser Sahara VDJ200R</Link></td>
                <td>10 days</td>
                <td>$145,536</td>
              </tr>
              <tr>
                <td>3.</td>
                <td>2018 Jeep Grand Cherokee Trackhawk WK</td>
                <td>11 days</td>
                <td>$129,565</td>
              </tr>
              <tr>
                <td>4.</td>
                <td><Link to="/price/2020-toyota-landcruiser-gxl-vdj200r/" title="2020 Toyota Landcruiser GXL VDJ200R price guide Australia">2020 Toyota Landcruiser GXL VDJ200R</Link></td>
                <td>12 days</td>
                <td>$108,212</td>
              </tr>
              <tr>
                <td>5.</td>
                <td>2019 RAM 1500 Laramie (No Series)</td>
                <td>15 days</td>
                <td>$113,058</td>
              </tr>
            </tbody>
          </table>
        </div>


        <Paragraph1>
          As with the cars above $50,000, the fastest selling cars in this group are also large 4WD that you can pile the family into and hit the road, with a caravan hooked up if you desire.
        </Paragraph1>

        <div>
          <strong>Fastest selling Audi, Mercedes and BMW’s valued over $50,000</strong>
          <table>
            <tbody>
              <tr>
                <td><strong>Rank</strong></td>
                <td><strong>Car make, model and year</strong></td>
                <td><strong>Average days to sell</strong></td>
                <td><strong>Average price</strong></td>
              </tr>
              <tr>
                <td>1.</td>
                <td><Link to="/price/2018-mercedes-benz-glc-class-glc250-x253/" title="2018 Mercedes-Benz GLC-Class GLC250 X253 price guide Australia">2018 Mercedes-Benz GLC-Class GLC250 X253</Link></td>
                <td>14 days</td>
                <td>$70,717</td>
              </tr>
              <tr>
                <td>2.</td>
                <td><Link to="/price/2018-mercedes-benz-cla-class-cla45-amg-c117/" title="2018 Mercedes-Benz CLA-Class CLA45 AMG C117 price guide Australia">2018 Mercedes-Benz CLA-Class CLA45 AMG C117</Link></td>
                <td>15 days</td>
                <td>$73,785</td>
              </tr>
              <tr>
                <td>3.</td>
                <td><Link to="/price/2019-mercedes-benz-a-class-a250-amg-line-w177/" title="2019 Mercedes-Benz A-Class A250 W177 price guide Australia">2019 Mercedes-Benz A-Class A250 W177</Link></td>
                <td>16 days</td>
                <td>$56,251</td>
              </tr>
              <tr>
                <td>4.</td>
                <td><Link to="/price/2018-bmw-x3-xdrive30d-g01/" title="2018 BMW X3 xDrive30d G01 price guide Australia">2018 BMW X3 xDrive30d G01</Link></td>
                <td>16 days</td>
                <td>$69,249</td>
              </tr>
              <tr>
                <td>5.</td>
                <td><Link to="/price/2018-bmw-m3-competition-f80-lci/" title="2018 BMW M3 Competition F80 LCI">2018 BMW M3 Competition F80 LCI</Link></td>
                <td>16 days</td>
                <td>$108,640</td>
              </tr>
            </tbody>
          </table>
        </div>


        <Paragraph1>
          Interestingly, there is much more variety in the prestige European cars, with small, big, fast and everything in-between.
          </Paragraph1>

        <Paragraph1>
          I was surprised that the BMW X5 was not in the Top 5, as these (in particular 7 seaters) from our experience are in hot demand. In fact they are averaging 25 days to sell, which is still considerably faster than an average sale (about 30 days), but not fast enough to be in our Top 5.
          </Paragraph1>

        <Paragraph1>
          For more information on average days to sell for a wide range of cars, along with prices, please visit our <Link to="/price/">Price Guide pages</Link>.
          </Paragraph1>

        <Paragraph1>
          If you are considering selling a prestige car, and are interested to know how quickly your car might sell, please reach out to us on <a href="mailto:hello@summon.com.au">hello@summon.com.au</a> and we can have a look for you.
          </Paragraph1>

      </ContentStyle>
    </SectionPageMargin>

    <ReadyToGetStarted />

  </Layout>

)

export default ValuationPage


const ContentStyle = styled.div`
  table {
    margin-top: 30px;
    margin-bottom: 50px;
    width: 100%;
    background-color: #f8f8f9;
  }

  tr {
    background-color: white;
    color: black;
  }

  th {
    padding: 10px;
    background-color: #3479ae;
    color: white;
  }

  td {
    padding: 5px;
    padding-left: 15px;
    background-color: #f8f8f9;
  }

  li {
    line-height: 2rem;
  }

`;
